import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface LayoutState {
  loading: boolean;
  drawerOpen: boolean;
}

export const initialState: LayoutState = {
  loading: false,
  drawerOpen: false,
};

const LayoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },

    openDrawer(state) {
      state.drawerOpen = true;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = LayoutSlice;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { HOSPITAL_INFO, appLink } from 'constants/app.constants';
import * as React from 'react';
import './styles.scss';
export function Footer() {
  //const hospitalCode = useSelector(sharedSelectors.selectHospitalCode);
  const hospitalCode = 'default';
  return (
    <footer className="footer-wrapper">
      <div
        className="animated fadeIn style_footer"
        style={{
          animationName: 'fadeIn',
          visibility: 'visible',
          animationIterationCount: 1,
        }}
      >
        <div className="style_footer_inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="style_footer_item mb-3">
                  <a href="">
                    <div className="lazyload-wrapper text-center ">
                      <img
                        src={HOSPITAL_INFO[hospitalCode].logoUrl}
                        className="img-fluid style_logo_footer"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="style_footer_item__2rIfV">
                  <ul className="list-group style_item_info">
                    <li className="list-group-item list-group-item-undefined">
                      <span className="style_text">
                        <strong className="text-uppercase">
                          {HOSPITAL_INFO[hospitalCode].name}
                        </strong>
                      </span>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <span>
                        Địa chỉ: {HOSPITAL_INFO[hospitalCode].address}
                      </span>
                    </li>
                    <li className="list-group-item list-group-item-undefined">
                      <a
                        style={{ color: '#1890FF', fontWeight: 'normal' }}
                        href={HOSPITAL_INFO[hospitalCode].website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Website: {HOSPITAL_INFO[hospitalCode].website}
                      </a>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <span>Email: {HOSPITAL_INFO[hospitalCode].email}</span>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <span>
                        Điện thoại: {HOSPITAL_INFO[hospitalCode].hotline}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                data-test="col"
                className="col-lg-2 pr-0 mt-3 mt-lg-0 d-lg-block"
              >
                <div className="style_footer_item">
                  <ul
                    data-test="list-group"
                    className="list-group style_item_info"
                  >
                    {/* <li
                    data-test="list-group-item"
                    className="list-group-item list-group-item-undefined"
                  >
                    <a href="lien-he">Liên hệ</a>
                  </li> */}
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <a href="dieu-khoan-dich-vu">Điều khoản dịch vụ</a>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <a href="chinh-sach-bao-mat">Chính sách bảo mật</a>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <a href="quy-dinh-su-dung">Quy định sử dụng</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="style_footer_item">
                  <ul className="list-group style_item_info style_icons">
                    <li className="list-group-item list-group-item-undefined d-none d-lg-block">
                      <a
                        href={appLink.google}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="lazyload-wrapper">
                          <img
                            src={
                              require('./images/googleplay.142f41c8.svg')
                                .default
                            }
                          />
                        </div>
                      </a>
                    </li>
                    <li className="list-group-item list-group-item-undefined d-none d-lg-block">
                      <a
                        href={appLink.apple}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="lazyload-wrapper ">
                          <img
                            src={
                              require('./images/appstore.cdb12f48.svg').default
                            }
                          />
                        </div>
                      </a>
                    </li>
                    <li
                      data-test="list-group-item"
                      className="list-group-item list-group-item-undefined"
                    >
                      <a
                        href=""
                        title="DMCA.com Protection Status"
                        className="dmca-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            require('./images/dmca_protected_sml_120m.png')
                              .default
                          }
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="style_footer_copy_right">
          <div data-test="container" className="container">
            <div data-test="row" className="row">
              <div data-test="col" className="col-12">
                <div className="style_footer_item">
                  © {new Date().getFullYear()} - Tekmedi
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectState = (state: RootState) => state.layout || initialState;

export const selectLoading = createSelector(
  [selectState],
  state => state.loading,
);

export const selectDrawerOpen = createSelector(
  [selectState],
  state => state.drawerOpen,
);

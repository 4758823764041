import React, { useState } from 'react';
import _ from 'lodash';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
type Props = {
  onClick?: () => void;
};

export default function SeachInfoPatient({ onClick }: Props) {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const { Search } = Input;

  const handleSeach = () => {
    if (!_.isEmpty(searchValue)) {
      onClick && onClick();
      history.push(`/tra-cuu-dang-ki-kham?q=${searchValue}`);
      setSearchValue('');
    }
  };

  return (
    <Search
      className="seach_wrapper d-flex align-items-center "
      placeholder="Nhập mã hẹn khám/SĐT"
      allowClear
      onSearch={(value: any) => handleSeach()}
      onChange={e => setSearchValue(e.target.value)}
      value={searchValue}
    />
  );
}

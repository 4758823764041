import React, { useState } from 'react';
import styled from 'styled-components';
import logo from 'img/Logo-tekemdi.png';
import phone from 'app/containers/HomeNew/images/phone.svg';
import qr from 'img/qr.svg';
import appStore from 'img/app-store.png';
import googlePlay from 'img/google-play.png';
import * as appConstants from 'constants/app.constants';
import CloseIcon from '@material-ui/icons/Close';

function renderQR() {
  return (
    <>
      <div className="col-3 p-0 d-none d-sm-flex align-items-center">
        <img src={qr} alt="qr" />
      </div>
      <div className="col-12 col-sm-9 p-0 pl-sm-4 d-flex flex-column justify-content-center align-items-start">
        <div className="text h5 font-bold d-none d-sm-block">
          Quét mã hoặc tải ứng dụng tại
        </div>
        <div className="row m-0">
          <div className="col-6 p-0">
            <a
              href={appConstants.appLink.apple}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="pr-1" src={appStore} alt="app-store" />
            </a>
          </div>
          <div className="col-6 p-0">
            <a
              href={appConstants.appLink.google}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="pl-1" src={googlePlay} alt="google-play" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
function renderButton() {
  return (
    <Button
      href={appConstants.appLink.download}
      target="_blank"
      rel="noopener noreferrer"
    >
      TẢI NGAY!
    </Button>
  );
}

export default function AppPopupProvider() {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <AppPopupContainer className={`row m-0 p-0 ${open ? '' : ' d-none'}`}>
      <Popup className="col-12 col-sm-8 col-md-7 col-lg-6 col-xl-5 p-4 p-sm-0">
        <CloseButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </CloseButton>
        <Header className="row m-0 p-3">
          <div className="col-12 col-sm-3 p-0 d-flex align-items-center">
            <img src={logo} alt="logo" />
          </div>
          <div className="col-12 col-sm-9 p-0 text mt-2 mt-sm-0">
            Ứng dụng đăng ký khám bệnh và thanh toán không dùng tiền mặt
          </div>
        </Header>
        <Content className="row m-0 p-2 p-sm-4 mt-2">
          <div className="col-5 col-sm-3 p-0">
            <img className="phone" src={phone} alt="phone" />
          </div>
          <div className="col-7 p-0 col-sm-9 d-flex d-sm-block flex-co justify-content-center align-items-center">
            <div className="row m-0 func">
              <ul>
                <li> Đăng ký khám bệnh online.</li>
                <li> Xếp hàng thông minh.</li>
                <li> Thanh toán không dùng tiền mặt.</li>
              </ul>
            </div>
            <div className="row m-0 mt-2 d-none d-sm-flex">{renderQR()}</div>
            <div className="row m-0 mt-2 d-none d-sm-block">
              {renderButton()}
            </div>
          </div>
          <div className="col-12 p-0 d-flex flex-column d-sm-none mt-2">
            {renderQR()}
          </div>
          <div className="col-12 p-0 d-block d-sm-none mt-4">
            {renderButton()}
          </div>
        </Content>
      </Popup>
    </AppPopupContainer>
  );
}

// Styles
const AppPopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000080;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 44px 10px 10px 10px !important;
  font-size: 16px;
  @media screen and (max-height: 550px) {
    font-size: 12px;
  }
`;
const Popup = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(4px);
  border-radius: 16px;
`;
const Header = styled.div`
  background: white;
  color: #d61b22;
  mix-blend-mode: normal;
  border-radius: 16px;
  padding: 20px;
  img {
    max-height: 40px;
    @media screen and (max-height: 550px) {
      max-height: 20px;
    }
  }
  .text {
    /* padding-left: 10px !important; */
    text-align: center;
    font-size: 1.3em;
    font-weight: 550;
    line-height: 1.3;
  }
  @media screen and (min-width: 576px) {
    background: url(header_bg.png);
    background-size: cover;
    color: white;
    img {
      content: url('logo-tekmedi-white.svg');
    }
  }
`;
const Content = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  img.phone {
    height: 100%;
    width: 100%;
    min-height: 236px;
    @media screen and (max-height: 550px) {
      min-height: 0;
      height: 160px !important;
    }
  }
  li {
    font-size: 1.2em;
  }
`;
const Button = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  background: #d61b22;
  border-radius: 8px;
  color: white;
  font-size: 1.4em;
  text-align: center;
  padding: 4px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
    color: white;
  }
`;
const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: -44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

import { API_MAIN } from 'constants/app.constants';
import * as _ from 'lodash';
import moment from 'moment';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { configResponseNew } from '../../utils/request';
import { actions } from '../search-information-user/slice';
import { SeachInformationHttp } from './services.http';
import * as authSelectors from 'store/auth/selectors';

const seachInformationHttp = new SeachInformationHttp(API_MAIN);
export function* getSearchInformationUser(api: SeachInformationHttp, action) {
  const userId = yield select(authSelectors.selectUserId);

  const response = yield call(api.searchUserInformation, {
    value: action?.payload?.searchValue,
    userId,
  });
  try {
    const data = yield configResponseNew(response);
    data.forEach(e => {
      if (!e.is_active) {
        e.status = 2;
      } else {
        const d = moment(
          moment(e?.apply_date).format('YYYY-MM-DD') + 'T' + e?.start_expected,
        );
        e.status = d.isAfter(moment()) ? 0 : 1;
      }
    });
    yield put(actions.getSearchInformationUserSuccess(data));
  } catch (error) {
    yield put(actions.clearSearchInformationUser([]));
    yield put(actions.getSearchInformationUserFailed(_.get(error, 'message')));
  }
}
export function* SearchInformationUserSaga() {
  yield all([
    yield takeLatest(
      actions.getSearchInformationUser.type,
      getSearchInformationUser,
      seachInformationHttp,
    ),
  ]);
}

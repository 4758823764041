/* eslint-disable @typescript-eslint/no-unused-vars */
import { API_MAIN } from 'constants/app.constants';
import { configRequest, HttpRequest } from 'utils/request';
import { APIRoutes } from './constant';
import { registerBooking } from './types';

export class ShareHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  specialistAll = body => {
    return this.request.post(APIRoutes.SPECIALIST_ALL, body);
  };

  specialistDepartment = (specialistCode: string, data: any) => {
    return this.request.post(
      APIRoutes.SPECIALIST_DEPARTMENT + specialistCode,
      data,
    );
  };

  getProvinces = () => {
    return this.request.get(APIRoutes.PROVINCES);
  };
  getHospitals = (data: any) => {
    return this.request.get(APIRoutes.HOSPITAL);
  };
  timeSlot = (data: any) => {
    return this.request.post(APIRoutes.TIME_SLOT, data);
  };

  getPatientCode = body => {
    return this.request.post(APIRoutes.GET_PATIENT, body);
  };

  getPatientCodes = (patientCode, hospitalCode, cardNumber) => {
    return this.request.get(
      `${APIRoutes.GET_PATIENTS}?patientCode=${
        patientCode || cardNumber
      }&hospitalCode=${hospitalCode}&cardNumber=${cardNumber || patientCode}`,
    );
  };

  getAllUserPatient = (userid: string) => {
    return this.request.get(`${APIRoutes.GET_ALL_USER_PATIENT}/${userid}`);
  };

  getRegisterInfoExaminationByUserId = (userId: string) => {
    return this.request.get(
      `${APIRoutes.REGISTER_BOOKING_BY_USER_ID}/${userId}`,
    );
  };

  postCancelExamination = (data: registerBooking) => {
    return this.request.post(APIRoutes.CANCEl_BOOKING_EXAMINATION, data);
  };
  postBookingCode = (booking_code: registerBooking, data: registerBooking) => {
    return this.request.post(`${APIRoutes.GET_BOOKING}/${booking_code}`, data);
  };
  getListReExamination = body => {
    return this.request.get(
      `${APIRoutes.GET_LIST_RE_EXAMINATION}/${body.userId}`,
      body,
    );
  };
}

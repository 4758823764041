/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapPatient } from './types.d';
import { API_MAIN } from 'constants/app.constants';
import { HttpRequest } from 'utils/request';
import { APIRoutes } from './constant';

export class ProfileHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  userPatientMap = (data: mapPatient) => {
    return this.request.post(APIRoutes.MAP_PATIENT, data);
  };

  userPatientUnMap = (data: mapPatient) => {
    return this.request.post(APIRoutes.UN_MAP_PATIENT, data);
  };
}

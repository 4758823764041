import { lazyLoad } from 'utils/loadable';
import { LoaderFallBack } from 'app/components/Loading';
import { withFooter } from 'app';

export const Home = withFooter(
  lazyLoad(
    () => import('app/containers/HomeNew'),
    module => module.default,
    LoaderFallBack(),
  ),
);

export const Introduce = withFooter(
  lazyLoad(
    () => import('app/containers/Introduce'),
    module => module.default,
    LoaderFallBack(),
  ),
);

export const Flow = withFooter(
  lazyLoad(
    () => import('app/containers/Flow'),
    module => module.default,
    LoaderFallBack(),
  ),
);

export const TermsOfService = withFooter(
  lazyLoad(
    () => import('app/containers/TermsOfServices'),
    module => module.TermsOfService,
    LoaderFallBack(),
  ),
);

export const PrivatePolices = withFooter(
  lazyLoad(
    () => import('app/containers/PrivatePolices'),
    module => module.PrivatePolices,
    LoaderFallBack(),
  ),
);

export const UsageRules = withFooter(
  lazyLoad(
    () => import('app/containers/UsageRules'),
    module => module.UsageRules,
    LoaderFallBack(),
  ),
);

export const LoginPage = withFooter(
  lazyLoad(
    () => import('app/containers/LoginPage'),
    module => module.LoginPage,
    LoaderFallBack(),
  ),
);

export const ChangePassword = withFooter(
  lazyLoad(
    () => import('app/containers/changePassWordPage'),
    module => module.default,
    LoaderFallBack(),
  ),
);

export const FromPatient = withFooter(
  lazyLoad(
    () => import('app/containers/InfoPatient'),
    module => module.FromPatient,
    LoaderFallBack(),
  ),
);

export const HospitalFee = withFooter(
  lazyLoad(
    () => import('app/containers/HospitalFeePayment'),
    module => module.HospitalFeePayment,
    LoaderFallBack(),
  ),
);

export const PaymentHistory = withFooter(
  lazyLoad(
    () => import('app/containers/PaymentHistory'),
    module => module.PaymentHistory,
    LoaderFallBack(),
  ),
);

export const Bill = withFooter(
  lazyLoad(
    () => import('app/containers/Bill'),
    module => module.Bill,
    LoaderFallBack(),
  ),
);

export const Profile = withFooter(
  lazyLoad(
    () => import('app/containers/AdvancedProfile'),
    module => module.Profile,
    LoaderFallBack(),
  ),
);

export const PatientRecords = withFooter(
  lazyLoad(
    () => import('app/containers/PatientRecords'),
    module => module.CheckPatientRecords,
    LoaderFallBack(),
  ),
);

export const NoticeAppointmentSuccess = withFooter(
  lazyLoad(
    () => import('app/containers/NoticeAppointmentSuccess'),
    module => module.NoticeAppointmentSuccess,
    LoaderFallBack(),
  ),
);

export const HistoryRegisterExamination = withFooter(
  lazyLoad(
    () => import('app/containers/HistoryRegisterExamination'),
    module => module.HistoryRegisterExamination,
    LoaderFallBack(),
  ),
);

export const SearchHistoryExamination = withFooter(
  lazyLoad(
    () => import('app/containers/SearchHistoryExamination'),
    module => module.SearchHistortExamination,
    LoaderFallBack(),
  ),
);
export const PaymentServices = withFooter(
  lazyLoad(
    () => import('app/containers/PaymentSevices'),
    module => module.PaymentSevices,
    LoaderFallBack(),
  ),
);
export const NoticeDetails = withFooter(
  lazyLoad(
    () => import('app/containers/NoticeDetails'),
    module => module.NoticeDetails,
    LoaderFallBack(),
  ),
);
export const HistoryReExamination = withFooter(
  lazyLoad(
    () => import('app/containers/HistoryRegisterExamination'),
    module => module.HistoryReExamination,
    LoaderFallBack(),
  ),
);

import { clearLocalStorage } from 'utils';
import { RoutePermissions } from 'router';
import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  loginLoading: boolean;
  linkLoading: boolean;
  error: any;
  success: boolean;
  userId: string | null;
  userName: string | null;
  token: string | null;
  permissions: RoutePermissions[];
  phoneNumberCurrent: string;
  userInformation: any;
  resultCheckNameUser: string | any;
  isCheckClick: boolean;
  isCheckLogin: boolean;
}

export const initialState: AuthState = {
  loading: false,
  loginLoading: false,
  linkLoading: false,
  error: null,
  success: false,
  resultCheckNameUser: '',
  userId: localStorage.getItem('__userId') || null,
  userName: localStorage.getItem('__userName') || null,
  token: localStorage.getItem('__token') || null,
  permissions: JSON.parse(localStorage.getItem('__permissions') || '[]'),
  phoneNumberCurrent: '',
  userInformation: JSON.parse(
    localStorage.getItem('__userInformation') || '[]',
  ),
  isCheckClick: false,
  isCheckLogin: false,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    registerGuest(state, action) {
      state.loading = true;
    },
    registerGuestSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userName = action.payload.userName;
    },
    registerGuestFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    registerGuestFailureBecauseAlready(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = false;
      state.userName = action.payload;
    },

    forgotPassword(state, action) {
      state.loginLoading = true;
    },
    forgotPasswordSuccess(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = true;
      state.userName = action.payload.userName;
    },
    forgotPasswordFailure(state, action) {
      state.loginLoading = false;
      state.error = action.payload;
      state.success = false;
      state.userName = null;
    },

    verificationAccount(state, action) {
      state.loading = true;
    },
    verificationAccountSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    verificationAccountFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },

    createPassword(state, action) {
      state.loginLoading = true;
    },
    createPasswordSuccess(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = true;
    },
    createPasswordFailure(state, action) {
      state.loginLoading = false;
      state.error = action.payload;
      state.success = false;
    },

    login(state, action) {
      state.loginLoading = true;
    },
    loginSuccess(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = true;
      state.userId = action.payload.id;
      state.userName = action.payload.userName;
      state.token = action.payload.token;
      state.permissions = [RoutePermissions.AUTH_CLIENT];
      state.userInformation = JSON.parse(action.payload.userInformation);

      localStorage.setItem('__userId', state.userId || '');
      localStorage.setItem('__userName', state.userName || '');
      localStorage.setItem('__token', action.payload.token);
      localStorage.setItem(
        '__permissions',
        JSON.stringify([RoutePermissions.AUTH_CLIENT]),
      );
      localStorage.setItem('__userInformation', action.payload.userInformation);
    },
    loginFailure(state, action) {
      state.loginLoading = false;
      state.error = action.payload;
      state.success = false;
    },

    logout(state) {
      state.loginLoading = false;
    },
    logoutSuccess(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = true;
      state.userId = null;
      state.userName = null;
      state.token = null;
      state.permissions = [];

      localStorage.removeItem('__userId');
      localStorage.removeItem('__userName');
      localStorage.removeItem('__token');
      localStorage.removeItem('__permissions');
      localStorage.removeItem('__userInformation');
    },
    logoutFailure(state, action) {
      state.loginLoading = false;
      state.error = action.payload;
      state.success = false;
    },
    phoneNumberCurrentLogin(state, action) {
      state.phoneNumberCurrent = action.payload;
    },
    cancelBooking(state, action) {
      state.loginLoading = true;
    },
    cancelBookingSuccess(state, action) {
      state.loginLoading = false;
      state.error = null;
      state.success = true;
    },
    cancelBookingFailure(state, action) {
      state.loginLoading = false;
      state.error = action.payload;
      state.success = false;
    },
    updatePatientInfo(state, action) {
      state.loginLoading = true;
      state.linkLoading = true;
    },
    updatePatientInfoSuccess(state, action) {
      const { userInformation } = action.payload;
      state.loginLoading = false;
      state.linkLoading = false;
      state.error = null;
      state.success = true;
      state.userInformation = JSON.parse(userInformation);
      localStorage.setItem('__userInformation', userInformation);
    },
    updatePatientInfoFail(state, action) {
      state.loginLoading = false;
      state.linkLoading = false;
      state.error = action.payload;
      state.success = false;
    },
    getUserDetail(state) {
      state.loading = true;
    },
    getUserDetailSuccess(state, action) {
      const { userInformation } = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userInformation = JSON.parse(userInformation);
      localStorage.setItem('__userInformation', userInformation);
    },
    getUserDetailFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    changePassWord(state, action) {
      state.loading = true;
    },
    changePassWordSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userId = null;
      state.token = null;
      state.permissions = [];
      clearLocalStorage();
    },
    changePassWordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    checkUserName(state, action) {
      state.loading = true;
      state.phoneNumberCurrent = action.payload.userName;
      state.userName = action.payload.userName;
    },
    checkUserNameSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.resultCheckNameUser = action.payload;
    },
    checkUserNameFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    clearCheckUserName(state, action) {
      state.resultCheckNameUser = action.payload;
    },
    checkLink(state, action) {
      state.isCheckClick = action.payload;
    },
    checkLogin(state, action) {
      state.isCheckLogin = action.payload;
    },
    resetUserInfomation(state) {
      state.userInformation = null;
      state.userName = null;
      state.phoneNumberCurrent = '';
      state.userId = null;
      state.token = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AuthSlice;

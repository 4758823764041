/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterProps } from './types';

export const ApiRoutes = {
  LIST: '/api/UserPatient/get',
  DETAIL: '/api/UserPatient/getbyid',
  ADD: '/api/UserPatient/add',
  UPDATE: '/api/UserPatient/update',
  REMOVE: '/api/UserPatient/remove',
  SET_DEFAULT: '/api/UserPatient/adddefault',
  FIND_PATIENT: '/api/Patient',
  FIND: '/api/UserPatient/find',
  GET_BHYT: '/api/Online/check_health_insurance',
};

export const defaultFilter: FilterProps = {
  userId: undefined,
  hospitalCode: undefined,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { Dropdown, Input, Menu } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import * as authSelectors from 'store/auth/selectors';
import * as AuthSlice from 'store/auth/slice';
import * as layoutSlice from 'store/layout/slice';
import * as ProfileSlice from 'store/profile/slice';
import { SearchInformationUserSaga } from 'store/search-information-user/saga';
import * as SearchInformationUserSlice from 'store/search-information-user/slice';
import * as SharedSlice from 'store/shared/slice';
import { clearLocalStorage } from 'utils';
import * as sharedSelectors from '../../../store/shared/selectors';
import SeachInfoPatient from '../SeachInfoPatient';
import * as multiProfileSlice from 'store/multi-profile/slice';
import { MultiProfileSaga } from 'store/multi-profile/saga';

import './styles.scss';

export function Header() {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useInjectReducer({
    key: multiProfileSlice.sliceKey,
    reducer: multiProfileSlice.reducer,
  });
  useInjectSaga({ key: multiProfileSlice.sliceKey, saga: MultiProfileSaga });
  useInjectReducer({
    key: ProfileSlice.sliceKey,
    reducer: ProfileSlice.reducer,
  });
  useInjectSaga({
    key: SearchInformationUserSlice.sliceKey,
    saga: SearchInformationUserSaga,
  });
  useInjectReducer({
    key: SearchInformationUserSlice.sliceKey,
    reducer: SearchInformationUserSlice.reducer,
  });

  const userInformation = useSelector(authSelectors.selectUserInformation);
  const AllPatientofUser = useSelector(sharedSelectors.selectAllPatientofUser);

  const [nameUser, setNameuser] = useState('');
  const [searchShow, setSearchShow] = useState<boolean>(true);

  const token = useSelector(authSelectors.selectToken);
  const userName = useSelector(authSelectors.selectUserName);

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <Link
          to={'/thong-tin-ca-nhan'}
          onClick={() => {
            dispatch(SharedSlice.actions.getAllUserPatientCode());
            dispatch(
              SharedSlice.actions.getPatientCode({
                patientCode: AllPatientofUser[0]?.patient_code,
                hospitalCode: AllPatientofUser[0]?.hospital_code,
              }),
            );
          }}
        >
          Hồ sơ bệnh nhân
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link to={'/lich-tai-kham'}>Lịch tái khám</Link>
      </Menu.Item>
      <Menu.Item key={3}>
        <Link to={'/lich-su-dang-ki-kham'}>Lịch sử đăng ký khám</Link>
      </Menu.Item>
      <Menu.Item key={4}>
        <Link to={'/doi-mat-khau'}>Đổi mật khẩu </Link>
      </Menu.Item>
      <Menu.Item key={5}>
        <div
          style={{ fontWeight: 'bold', fontSize: 14 }}
          onClick={() => {
            dispatch(AuthSlice.actions.logoutSuccess(null));
            dispatch(SharedSlice.actions.clear());
            dispatch(SharedSlice.actions.resetUserInforPatientCodeHopital());
            clearLocalStorage();
            history.push('/dang-nhap');
            dispatch(AuthSlice.actions.checkLink(true));
            dispatch(AuthSlice.actions.clearCheckUserName(''));
            dispatch(multiProfileSlice.actions.reset());
          }}
        >
          Đăng xuất
        </div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (userInformation && userName) {
      const split = userInformation?.full_name?.trim().split(' ') as string[];
      setNameuser(split?.slice(split.length - 2, split.length).join(' '));
    }
  }, [userInformation]);
  useEffect(() => {
    if (pathname === '/tra-cuu-dang-ki-kham') {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  }, [pathname]);
  const renderUserName = (type: 'mobile' | 'desktop') => {
    return userName && token !== null ? (
      <div className="style__info d-flex">
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['hover']}>
          <button className="header__info">
            <img
              width={25}
              style={{ borderRadius: '50%', marginRight: 10 }}
              src={require('img/avatar-placeholder.jpg').default}
              alt="picksum"
            />
            <div
              style={{
                textAlign: 'center',
                paddingTop: '7px',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {token && userInformation?.full_name?.length > 13
                ? nameUser
                : userInformation?.full_name || userName}
              <i
                style={{ marginLeft: '5px', color: '#8F8F8F' }}
                className="fas fa-angle-down"
              />
            </div>
          </button>
        </Dropdown>
        {searchShow && (
          <div className="header-form hideSeach" style={{ maxWidth: '230px' }}>
            <SeachInfoPatient />
          </div>
        )}
      </div>
    ) : (
      <div className="style__info d-flex ">
        <div
          style={{
            marginRight: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            history.push('/dang-nhap');
            dispatch(AuthSlice.actions.checkLink(true));
            dispatch(AuthSlice.actions.clearCheckUserName(''));
          }}
        >
          {type === 'desktop' ? (
            <>
              <PermIdentityOutlinedIcon className="header__info__content" />
              <span style={{ color: '#D61B22', fontWeight: 'bold' }}>
                Đăng ký/ Đăng nhập
              </span>
            </>
          ) : (
            <div className="styleBtn_LoginMobile">Đăng nhập</div>
          )}
        </div>
        {searchShow && (
          <div className="header-form hideSeach" style={{ maxWidth: '230px' }}>
            <SeachInfoPatient />
          </div>
        )}
      </div>
    );
  };
  return (
    <header className="header-wrapper">
      <div className="container-fluid">
        <div
          data-test="animation"
          className="animated fadeIn  header__style d-none d-lg-block"
          style={{
            animationIterationCount: 1,
            visibility: 'visible',
            animationName: 'fadeIn',
          }}
        >
          <div>
            <div className="row">
              <div className="col-3 col-xl-3 header_navBar">
                <Link to="/">
                  <img
                    src={require('./images/header_logo.png').default}
                    alt="Tekmedi"
                  />
                </Link>
              </div>
              <nav className="col-4 col-xl-5 header_navBar">
                <div>
                  <nav
                    data-test="navbar"
                    className="navbar navbar-expand-lg header_content"
                    role="navigation"
                  >
                    <button
                      data-test="navbar-toggler"
                      type="button"
                      // style_mdbNavbarToggler__3kBAx
                      className="navbar-toggler "
                    >
                      <i className="fas fa-bars" />
                    </button>
                    <div
                      id="navbarCollapse3"
                      // style_mdbCollapse__30xjE
                      className="collapse navbar-collapse "
                    >
                      <ul className="navbar-nav justify-content-around style__navBar">
                        <li
                          className={`nav-item ${
                            pathname === '/' && 'style__navBar__active'
                          }`}
                        >
                          <Link className="nav-link" to="/">
                            Trang chủ
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            pathname === '/gioi-thieu' &&
                            'style__navBar__active'
                          }`}
                        >
                          <Link className="nav-link" to="/gioi-thieu">
                            Giới thiệu
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            pathname === '/quy-trinh' && 'style__navBar__active'
                          }`}
                        >
                          <Link className="nav-link " to="/quy-trinh">
                            Quy trình
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </nav>
              <div className="col-5  col-xl-4 header_navBar d-flex align-items-center">
                {renderUserName('desktop')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile  */}
      <div
        className="d-lg-none"
        style={{ backgroundColor: 'white', padding: '5px 10px' }}
      >
        <Grid container>
          <Grid item xs={userName && token !== null ? 5 : 7} sm={4}>
            <div className="header__mini__menu d-flex align-item--center">
              <div
                onClick={() => dispatch(layoutSlice.actions.openDrawer())}
                className="d-flex align-items-center h-full mr-3 justify-content-center"
                style={{ cursor: 'pointer', width: '40px' }}
              >
                <i className="fas fa-bars" style={{ fontSize: '20px' }} />
              </div>
              <Link
                to={'/'}
                className=" justify-content-flex-start align-items-center d-flex h-full w-100"
              >
                <img
                  className="style_img_hospital"
                  style={{ height: '60%' }}
                  src={require('img/logoTekmedi.svg').default}
                  alt="logo"
                />
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={userName && token !== null ? 7 : 5}
            sm={8}
            className="d-flex align-items-center justify-content-center"
          >
            {renderUserName('mobile')}
          </Grid>
        </Grid>
      </div>
    </header>
  );
}

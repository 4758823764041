import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface FeatureState {
  loading: boolean;
  examHospitals: any[];
  register_type: any[];
}

export const initialState: FeatureState = {
  loading: false,
  examHospitals: [],
  register_type: [],
};

const FeatureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    getFeature(state, action) {
      state.loading = true;
    },
    getFeatureSuccess(state, action) {
      state.loading = false;
      const setting = JSON.parse(
        action.payload?.home_features
          ?.find(f => f?.code === 'EXAM')
          ?.sub_features?.find(sf => sf?.code === 'GENERAL_EXAM')?.config,
      );
      state.examHospitals = setting?.hospitals;
      state.register_type = setting?.register_type;
    },
    getFeatureFailure(state, action) {
      state.loading = false;
      state.examHospitals = [];
      state.register_type = [];
    },
  },
});

export const { actions, reducer, name: sliceKey } = FeatureSlice;

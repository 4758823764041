import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface SearchInformationUserState {
  dataSearchInformationUser: any[];
  dataSearchInformationUserFilter: any[];
  loadingSearch: boolean;
  error: null;
  success: boolean;
}

export const initialState: SearchInformationUserState = {
  dataSearchInformationUser: [],
  dataSearchInformationUserFilter: [],
  loadingSearch: false,
  error: null,
  success: false,
};

const SearchInformationUserSlice = createSlice({
  name: 'searchInformationUser',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    getSearchInformationUser(state, action) {
      state.loadingSearch = true;
    },
    getSearchInformationUserSuccess(state, action) {
      state.loadingSearch = false;
      state.error = null;
      state.success = true;
      state.dataSearchInformationUser = action.payload;
      state.dataSearchInformationUserFilter = action.payload;
    },
    clearSearchInformationUser(state, action) {
      state.dataSearchInformationUser = action.payload;
      state.dataSearchInformationUserFilter = action.payload;
    },
    getSearchInformationUserFailed(state, action) {
      state.loadingSearch = false;
      state.error = action.payload;
      state.success = false;
    },
    filteredSearchPatientList(state, action) {
      state.dataSearchInformationUserFilter = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = SearchInformationUserSlice;

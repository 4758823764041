import { createSlice } from 'store/core/@reduxjs/toolkit';

export interface ProfileState {
  clickProfile: boolean;
  loadingProfile: boolean;
  error: any;
  success: boolean;
}

export const initialState: ProfileState = {
  clickProfile: false,
  loadingProfile: false,
  error: null,
  success: false,
};

const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    checkClickProfile(state, action) {
      state.clickProfile = action.payload;
    },
    postLinkPatientMap(state, action) {
      state.loadingProfile = true;
    },
    postLinkPatientMapSuccess(state, action) {
      state.loadingProfile = false;
      state.error = null;
      state.success = true;
    },
    postLinkPatientMapFailed(state, action) {
      state.loadingProfile = false;
      state.error = action.payload;
      state.success = false;
    },
    postLinkPatientUnMap(state, action) {
      state.loadingProfile = true;
    },
    postLinkPatientUnMapSuccess(state, action) {
      state.loadingProfile = false;
      state.error = null;
      state.success = true;
    },
    postLinkPatientUnMapFailed(state, action) {
      state.loadingProfile = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = ProfileSlice;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FunctionComponent } from 'react';
import * as Router from 'router/lazyRouting';

export interface AppRoute {
  path: string;
  title: string;
  Component: FunctionComponent;
  permission?: RoutePermissions;
  role?: RouteRoles;
}

export enum RouteRoles {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum RoutePermissions {
  CLIENT = 'CLIENT.INDEX',
  AUTH_CLIENT = 'AUTH_CLIENT.INDEX',
}

export const AppRouting: AppRoute[] = [
  {
    path: '/',
    title: 'Trang chủ',
    Component: Router.Home,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/gioi-thieu',
    title: 'Giới thiệu',
    Component: Router.Introduce,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/quy-trinh',
    title: 'Quy trình',
    Component: Router.Flow,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/dieu-khoan-dich-vu',
    title: 'Điều khoản dịch vụ',
    Component: Router.TermsOfService,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/chinh-sach-bao-mat',
    title: 'Chính sách bảo mật',
    Component: Router.PrivatePolices,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/quy-dinh-su-dung',
    title: 'Quy định sử dụng',
    Component: Router.UsageRules,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/dang-nhap',
    title: 'Đăng nhập',
    Component: Router.LoginPage,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/doi-mat-khau',
    title: 'Đổi mật khẩu',
    Component: Router.ChangePassword,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/dang-ky-kham-benh',
    title: 'Đăng ký khám bệnh',
    Component: Router.FromPatient,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/thanh-toan-vien-phi',
    title: 'Thanh toán viện phí',
    Component: Router.HospitalFee,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/lich-su-thanh-toan-vien-phi',
    title: 'Lịch sử thanh toán viện phí',
    Component: Router.PaymentHistory,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/hoa-don-dien-tu',
    title: 'Hoá đơn điện tử',
    Component: Router.Bill,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/thong-tin-ca-nhan',
    title: 'Thông tin cá nhân',
    Component: Router.Profile,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/thiet-lap-ho-so-benh-nhan/:id',
    title: 'Thiết lập hồ sơ bệnh nhân',
    Component: Router.PatientRecords,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/dat-lich-kham',
    title: 'Đặt lịch khám',
    Component: Router.NoticeAppointmentSuccess,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/lich-su-dang-ki-kham',
    title: 'Lịch sử đăng ký khám',
    Component: Router.HistoryRegisterExamination,
    permission: RoutePermissions.AUTH_CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/tra-cuu-dang-ki-kham',
    title: 'Tra cứu đăng ký khám',
    Component: Router.SearchHistoryExamination,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/Thanh-toan-phi-tien-ich',
    title: 'Thanh toán phí tiện ích',
    Component: Router.PaymentServices,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/Chi-tiet-thong-bao',
    title: 'Chi tiết thông báo',
    Component: Router.NoticeDetails,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
  {
    path: '/Lich-tai-kham',
    title: 'Lịch tái khám',
    Component: Router.HistoryReExamination,
    permission: RoutePermissions.CLIENT,
    role: RouteRoles.USER,
  },
];

/* eslint-disable @typescript-eslint/no-unused-vars */
import { API_MAIN } from 'constants/app.constants';
import { HttpRequest } from 'utils/request';
import { ApiRoutes } from './constant';

export class MultiProfileHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  list = body => {
    return this.request.get(ApiRoutes.LIST, body);
  };

  detail = body => {
    return this.request.get(ApiRoutes.DETAIL, body);
  };

  add = body => {
    return this.request.post(ApiRoutes.ADD, body);
  };

  update = body => {
    return this.request.post(ApiRoutes.UPDATE, body);
  };

  remove = body => {
    return this.request.delete(ApiRoutes.REMOVE, body);
  };

  setDefault = body => {
    return this.request.post(ApiRoutes.SET_DEFAULT, body);
  };

  findPatient = (hospitalCode, searchKey) => {
    return this.request.get(ApiRoutes.FIND_PATIENT, {
      hospitalCode,
      patientCode: searchKey,
      cardNumber: searchKey,
    });
  };
  find = body => {
    return this.request.post(ApiRoutes.FIND, body);
  };
  getBhyt = body => {
    return this.request.post(ApiRoutes.GET_BHYT, body);
  };
}

import moment from 'moment';

export function formatApiDateOnly(date: any) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatApiTimeOnly(date: any) {
  return moment(date).format('hh:mm:ss');
}

export function removeAscent(str) {
  if (str === null || str === undefined) return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  return str;
}

export function validName(value) {
  // eslint-disable-next-line no-useless-escape
  var re = /^[a-zA-Z!@#\$%\^\&*\)\(+=._-]{2,}$/g;
  return re.test(removeAscent(value));
}

export function clearLocalStorage() {
  localStorage.removeItem('__userId');
  localStorage.removeItem('__token');
  localStorage.removeItem('__userName');
  localStorage.removeItem('__userInformation');
}

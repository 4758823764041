/* eslint-disable @typescript-eslint/no-unused-vars */
import { API_MAIN } from 'constants/app.constants';
import { HttpRequest } from 'utils/request';
import { ApiRoutes } from './constant';

export class FeatureHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  getFeature = body => {
    return this.request.get(ApiRoutes.GET_FEATURE, body);
  };
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectAuthState = (state: RootState) => state.auth || initialState;

export const selectLoading = createSelector(
  [selectAuthState],
  auth => auth.loading,
);

export const selectLoginLoading = createSelector(
  [selectAuthState],
  auth => auth.loginLoading,
);
export const selectLinkLoading = createSelector(
  [selectAuthState],
  auth => auth.linkLoading,
);

export const selectError = createSelector(
  [selectAuthState],
  auth => auth.error,
);

export const selectSuccess = createSelector(
  [selectAuthState],
  auth => auth.success,
);

export const selectUserId = createSelector(
  [selectAuthState],
  auth => auth.userId,
);

export const selectUserName = createSelector(
  [selectAuthState],
  auth => auth.userName,
);

export const selectPermissions = createSelector(
  [selectAuthState],
  auth => auth.permissions,
);

export const selectToken = createSelector(
  [selectAuthState],
  auth => auth.token,
);

export const selecPhoneNumberCurrent = createSelector(
  [selectAuthState],
  auth => auth.phoneNumberCurrent,
);
export const selectUserInformation = createSelector(
  [selectAuthState],
  auth => auth.userInformation,
);
export const selectResultCheckUserName = createSelector(
  [selectAuthState],
  auth => auth.resultCheckNameUser,
);

export const selectCheckLink = createSelector(
  [selectAuthState],
  auth => auth.isCheckClick,
);

export const selectCheckLogin = createSelector(
  [selectAuthState],
  auth => auth.isCheckLogin,
);

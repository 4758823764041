/* eslint-disable @typescript-eslint/no-unused-vars */

export const APIRoutes = {
  SPECIALIST_ALL: '/api/Online/localspecialist/all',
  SPECIALIST_DEPARTMENT: '/api/Online/localspecialist/department/',
  GET_PATIENTS: 'api/Patient',
  GET_PATIENT: '/api/SysUser/find',
  GET_ALL_USER_PATIENT: '/api/UserPatient/all',
  CANCEl_BOOKING_EXAMINATION: '/api/Online/cancel-examination',
  GET_BOOKING: 'api/Online/patient',
  TIME_SLOT: '/api/Online/time-slot',
  REGISTER_BOOKING_BY_USER_ID: '/api/Online/registered/user',
  HOSPITAL: 'api/Hospital',
  PROVINCES: 'api/Location/all',
  GET_LIST_RE_EXAMINATION: 'api/Online/againregistered/user',
};

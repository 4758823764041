// Google analysis
export function gtagSendPageView(url: string) {
  if (!['PROD', 'UAT'].includes(process.env.REACT_APP_CFG_ENV as string))
    return;
  if (!(window as any)?.gtag) return;
  if (
    window.location.hostname !==
    new URL(process.env.REACT_APP_GA_SITE as string).hostname
  )
    return;
  (window as any).gtag('event', 'page_view', {
    event_category: 'traffic',
    event_label: url,
    value: url,
  });
}

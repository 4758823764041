import * as _ from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { configResponse } from 'utils/request';
import { MultiProfileHttp } from './services.http';
import { actions } from './slice';

const service = new MultiProfileHttp();

export function* list(action) {
  const response = yield call(service.list, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.listSuccess(data));
  } catch (error) {
    yield put(actions.listFailure(_.get(error, 'message')));
  }
}

export function* detail(action) {
  const response = yield call(service.detail, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.detailSuccess(data));
  } catch (error) {
    yield put(actions.detailFailure(_.get(error, 'message')));
  }
}

export function* add(action) {
  const response = yield call(service.add, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.addSuccess(data));
  } catch (error) {
    yield put(actions.addFailure(_.get(error, 'message')));
  }
}

export function* update(action) {
  const response = yield call(service.update, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.updateSuccess(data));
  } catch (error) {
    yield put(actions.updateFailure(_.get(error, 'message')));
  }
}

export function* remove(action) {
  const response = yield call(service.remove, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.removeSuccess(data));
  } catch (error) {
    yield put(actions.removeFailure(_.get(error, 'message')));
  }
}

export function* setDefault(action) {
  const response = yield call(service.setDefault, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.setDefaultSuccess(data));
  } catch (error) {
    yield put(actions.setDefaultFailure(_.get(error, 'message')));
  }
}

export function* findPatient(action) {
  const response = yield call(
    service.findPatient,
    action.payload.hospitalCode,
    action.payload.searchKey,
  );
  try {
    const data = yield configResponse(response);
    yield put(actions.findPatientSuccess(data));
  } catch (error) {
    yield put(actions.findPatientFailure(_.get(error, 'message')));
  }
}
export function* find(action) {
  const response = yield call(service.find, action.payload);
  try {
    const data = yield configResponse(response);
    yield put(actions.findSuccess(data));
  } catch (error) {
    yield put(actions.findFailure(_.get(error, 'message')));
  }
}
export function* getBhyt(action) {
  const response = yield call(service.getBhyt, {
    ...action.payload,
    type: 'online',
  });
  try {
    const data = yield configResponse(response);
    if (data.status === 1) {
      yield put(actions.getBhytSuccess(data));
    } else {
      yield put(actions.getBhytFailure(data));
    }
  } catch (error) {
    yield put(actions.getBhytFailure(_.get(error, 'message')));
  }
}

export function* MultiProfileSaga() {
  yield all([
    yield takeLatest(actions.list.type, list),
    yield takeLatest(actions.detail.type, detail),
    yield takeLatest(actions.add.type, add),
    yield takeLatest(actions.update.type, update),
    yield takeLatest(actions.remove.type, remove),
    yield takeLatest(actions.setDefault.type, setDefault),
    yield takeLatest(actions.findPatient.type, findPatient),
    yield takeLatest(actions.find.type, find),
    yield takeLatest(actions.getBhyt.type, getBhyt),
  ]);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { API_MAIN } from 'constants/app.constants';
import { HttpRequest } from 'utils/request';
import { APIRoutes } from './constant';

export class SeachInformationHttp {
  request: any;
  endPoint: any;
  constructor(endPoint = API_MAIN) {
    this.request = new HttpRequest(endPoint).request;
    this.endPoint = endPoint;
  }

  searchUserInformation = body => {
    return this.request.get(
      `${APIRoutes.SEARCH_INFORMATION_USER}/${body.value}`,
      { userId: body.userId },
    );
  };
}

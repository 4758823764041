/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { configResponse } from 'utils/request';
import { actions } from './slice';
import * as _ from 'lodash';
import { FeatureHttp } from './services.http';
import { API_MAIN } from 'constants/app.constants';

const featureHttp = new FeatureHttp(API_MAIN);

export function* GetFeature(api, action) {
  const response = yield call(featureHttp.getFeature, {
    ...action.payload,
    os: 'IOS',
    version: process.env.REACT_APP_VERSION || '0',
  });
  try {
    const data = yield configResponse(response);
    yield put(actions.getFeatureSuccess(data));
  } catch (error) {
    yield put(actions.getFeatureFailure(_.get(error, 'message')));
  }
}

export function* FeatureSaga() {
  yield all([
    yield takeLatest(actions.getFeature.type, GetFeature, featureHttp),
  ]);
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/core/types';
import { initialState } from './slice';

const selectSharedState = (state: RootState) => state.shared || initialState;

export const selectLoading = createSelector(
  [selectSharedState],
  shared => shared.loading,
);

export const selectError = createSelector(
  [selectSharedState],
  shared => shared.error,
);

export const selectSuccess = createSelector(
  [selectSharedState],
  shared => shared.success,
);

export const selectHospitalCode = createSelector(
  [selectSharedState],
  shared => shared.hospitalCode,
);

export const selectSpecialists = createSelector(
  [selectSharedState],
  shared => shared.specialists,
);

export const selectDepartments = createSelector(
  [selectSharedState],
  shared => shared.departments,
);

export const selectListNumberOnline = createSelector(
  [selectSharedState],
  shared => shared.listNumberOnline,
);
export const selectUserInfoPatientCode = createSelector(
  [selectSharedState],
  shared => shared.userInforPatientCode,
);

export const selectUserInfoPatientCodeHopital = createSelector(
  [selectSharedState],
  shared => shared.userInforPatientCodeHopital,
);

export const selectUserInforPatientCodeSearch = createSelector(
  [selectSharedState],
  shared => shared.userInforPatientCodeSearch,
);

export const selectAllPatientofUser = createSelector(
  [selectSharedState],
  shared => shared.allPatientofUser,
);
export const selectFilterInfoRegisterExamination = createSelector(
  [selectSharedState],
  shared => shared.infoRegisterExaminationFilter,
);
export const selectInformationUserBookingCode = createSelector(
  [selectSharedState],
  shared => shared.informationUserBookingCode,
);

export const selectIsCheckLinkHomePage = createSelector(
  [selectSharedState],
  shared => shared.isCheckLinkHomePage,
);
export const selectIsLinkRegisterPage = createSelector(
  [selectSharedState],
  shared => shared.isLinkReGisterPage,
);

export const selectIsLoginPage = createSelector(
  [selectSharedState],
  shared => shared.isLinkLoginPage,
);

export const valueSearchHistory = createSelector(
  [selectSharedState],
  shared => shared.valueSearchHistory,
);
export const selectSeachValuesCurrent = createSelector(
  [selectSharedState],
  shared => shared.seachValuesCurrent,
);
export const selectReExamination = createSelector(
  [selectSharedState],
  shared => shared.ListReExamination,
);

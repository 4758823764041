import { spring } from 'react-router-transition';

export function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: styles.scale === 1 ? 'unset' : `scale(${styles.scale})`,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 100,
    damping: 10,
  });
}

export const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 1.1,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(1),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};
